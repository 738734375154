// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".pill-component__toolbarInput___z1dQC{background-color:#fff;border:1px solid #bbb;border-radius:40px;height:30px;display:flex;justify-content:space-between;align-items:center;flex-direction:row;padding-left:16px;padding-right:2px;z-index:100;text-wrap:nowrap}.pill-component__toolbarInputDivider___1t5ZI{width:1px;height:30px;background-color:#bbb}.pill-component__dropdownIcon___fH4y8{width:10px;height:5px;margin-right:10px;cursor:pointer}", "",{"version":3,"sources":["/codebuild/output/src351705177/src/src/components/core/styles/pill-component.scss"],"names":[],"mappings":"AAAA,sCACE,qBAAA,CACA,qBAAA,CACA,kBAAA,CACA,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CACA,WAAA,CACA,gBAAA,CAGF,6CACE,SAAA,CACA,WAAA,CACA,qBAAA,CAGF,sCACE,UAAA,CACA,UAAA,CACA,iBAAA,CACA,cAAA","file":"pill-component.scss","sourcesContent":[".toolbarInput {\n  background-color: #ffffff;\n  border: 1px solid #bbbbbb;\n  border-radius: 40px;\n  height: 30px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: row;\n  padding-left: 16px;\n  padding-right: 2px;\n  z-index: 100;\n  text-wrap: nowrap;\n}\n\n.toolbarInputDivider {\n  width: 1px;\n  height: 30px;\n  background-color: #bbbbbb;\n}\n\n.dropdownIcon {\n  width: 10px;\n  height: 5px;\n  margin-right: 10px;\n  cursor: pointer;\n}\n"]}]);
// Exports
exports.locals = {
	"toolbarInput": "pill-component__toolbarInput___z1dQC",
	"toolbarInputDivider": "pill-component__toolbarInputDivider___1t5ZI",
	"dropdownIcon": "pill-component__dropdownIcon___fH4y8"
};
module.exports = exports;
