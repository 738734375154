import React, { FC } from 'react';
import styles from './styles/self-enroll-upgrade.scss';
import { Button, CheckBox } from 'shamrock-clover-ui';
import ChevronLeftBlue from '../../assets/chevron-left-blue.svg';
import { useTranslation } from 'react-i18next';
import { TextField } from 'drift-ui';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configureStore';
import { formatPhoneNumber } from '../../utils/string-utils';
import { ViewState } from './request-upgrade';

type SelfEnrollUpgradeProps = {
  planName: string;
  setViewState: React.Dispatch<React.SetStateAction<ViewState>>;
};

export const SelfEnrollUpgrade: FC<SelfEnrollUpgradeProps> = ({ planName, setViewState }) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.app.user);
  const [upgradeForm, setUpgradeForm] = React.useState({
    firstname: user?.firstname ?? '',
    lastname: user?.lastname ?? '',
    email: user?.email ?? '',
    phone: formatPhoneNumber(user?.phone) ?? '',
    plan: planName,
    company: user?.company ?? '',
  });
  const [purchaseAcceptance, setPurchaseAcceptance] = React.useState(false);
  const [costAcceptance, setCostAcceptance] = React.useState(false);

  const formFieldChange = (field: string, value: string) => {
    let updateValue = '';
    if (field === 'phone') {
      updateValue = formatPhoneNumber(value);
    } else {
      updateValue = value;
    }
    setUpgradeForm((prev) => ({ ...prev, [field]: updateValue }));
  };
  return (
    <div className={styles.selfEnrollContainer}>
      <Button buttonStyle="text" width="60px" height="20px" onClick={() => setViewState(ViewState.DEFAULT)}>
        <div className={styles.backButton}>
          <img className={styles.backArrow} src={ChevronLeftBlue} />
          Back
        </div>
      </Button>
      <div className={styles.textContainer}>
        <p className={styles.titleText}>{t('upgrade_your_pt_plan')}</p>
        <p className={styles.descriptionText}>{t('use_your_factoring_funds')}</p>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.columnContainer}>
          <TextField
            label="First Name"
            value={upgradeForm.firstname}
            onChange={(value) => formFieldChange('firstname', value.currentTarget.value)}
          />
          <TextField label="Email" value={upgradeForm.email} disabled />
          <TextField label="Company Name" value={upgradeForm.company} disabled />
        </div>
        <div className={styles.columnContainer}>
          <TextField
            label="Last Name"
            value={upgradeForm.lastname}
            onChange={(value) => formFieldChange('lastname', value.currentTarget.value)}
          />
          <TextField
            label="Phone"
            value={upgradeForm.phone}
            onChange={(value) => formFieldChange('phone', value.currentTarget.value)}
          />
          <TextField label="Plan" value={upgradeForm.plan} disabled />
        </div>
      </div>
      <div className={styles.checkboxContainer}>
        <div className={styles.checkbox}>
          <CheckBox checked={purchaseAcceptance} onClick={() => setPurchaseAcceptance((prev) => !prev)} />
          <p className={styles.checkboxText}>{t('purchase_acceptance_text')}</p>
        </div>
        <div className={styles.checkbox}>
          <CheckBox checked={costAcceptance} onClick={() => setCostAcceptance((prev) => !prev)} />
          <p className={styles.checkboxText}>{t('cost_acceptance_text', { cost: 125 })}</p>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button>{t('upgrade_to_pt_premium')}</Button>
      </div>
      <p className={styles.disclaimerText}>{t('disclaimer_text_1')}</p>
      <p className={styles.disclaimerText}>{t('disclaimer_text_2')}</p>
    </div>
  );
};
