import { Button, DataGrid, Divider, Menu, MenuItem, OpenInNewIcon } from 'drift-ui';
import React, { useEffect } from 'react';
import css from './styles/notifications-drawer.scss';
import { GridColDef, GridRowId, GridSortDirection } from 'drift-ui/dist/drift/components/datagrid/DataGrid';
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params/gridRowParams';
import NotificationsComplianceMenu from './notifications-drawer-compliance-menu';
import { VisibilityOutlined, MoreVert } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/configureStore';
import { setNotificationStatus } from '../../redux/actions/notificationsActions';
import { ComplianceFilters, ComplianceRow } from '../../redux/reducers/notificationsReducer';
import NotificationService from '../../services/notification.service';
import { ListItemIcon, ListItemText } from '@mui/material';
import { EmptyState } from '../core/empty-state';
import EmptyStateIcon from '../../assets/notifications-empty-state.svg';
import WarningRedImage from '../../assets/warning-red.svg';
import WarningYellowImage from '../../assets/warning-yellow.svg';

const { DriftDataGrid } = DataGrid;

export default function NotificationsDrawerCompliance() {
  const [checkedRows, setCheckedRows] = React.useState<GridRowId[]>([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const dispatch = useAppDispatch();
  const isLoading: boolean = useAppSelector((state) => state.notifications.compliance.isLoading);
  const complianceRows: ComplianceRow[] = useAppSelector((state) => state.notifications.compliance.rows);
  const filters: ComplianceFilters = useAppSelector((state) => state.notifications.compliance.filters);
  const [displayRows, setDisplayRows] = React.useState(complianceRows);
  const [sortModel, setSortModel] = React.useState<{ field: string; sort: GridSortDirection }[]>([
    { field: 'expiresIn', sort: 'asc' },
  ]);

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const notificationService = new NotificationService();

  const handleBulkActionClick = (event: any, item: ComplianceRow) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleBulkActionClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const filterRows = () => {
    const { searchText, searchType, warningType } = filters;
    const hasSearchText = searchText && searchText.trim() !== '';
    const hasSearchType = searchType && searchType.trim() !== '';
    const hasWarningType = warningType && warningType !== '';

    return complianceRows.filter((row) => {
      const matchesSearchText = hasSearchText
        ? Object.keys(row).some((key) => {
            if (key === 'id') return false;
            return row[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase());
          })
        : true;

      let matchesSearchType = true;
      if (hasSearchType) {
        if (searchType === t('expired')) {
          matchesSearchType = row?.expiresIn <= 0;
        } else if (searchType === t('30_days')) {
          matchesSearchType = row?.expiresIn > 0 && row?.expiresIn <= 30;
        } else if (searchType === t('60_days')) {
          matchesSearchType = row?.expiresIn > 0 && row?.expiresIn <= 60;
        }
      }

      let matchesWarningType = true;
      if (hasWarningType) {
        if (warningType === t('all_warnings')) {
          matchesWarningType = row?.type != null;
        } else if (warningType === t('Driver')) {
          matchesWarningType = row?.type === 'Driver';
        } else if (warningType === t('Truck')) {
          matchesWarningType = row?.type === 'Truck';
        } else if (warningType === t('trailer')) {
          matchesWarningType = row?.type === 'Trailer';
        }
      }

      return matchesSearchText && matchesSearchType && matchesWarningType;
    });
  };

  useEffect(() => {
    setDisplayRows(filterRows());
  }, [filters]);

  const columns: GridColDef<ComplianceRow>[] = [
    {
      field: 'type',
      headerName: t('Type'),
      width: 85,
    },
    {
      field: 'expiresIn',
      headerName: t('expires_in'),
      width: 115,
      renderCell: (params) => (
        <div className={css.expiresIn}>
          {params.value <= 0 && <img src={WarningRedImage} alt={''} className={css.warningRed} />}
          {params.value > 0 && params.value <= 30 && (
            <img src={WarningYellowImage} alt={''} className={css.warningYellow} />
          )}
          {params.value} days
        </div>
      ),
    },
    {
      field: 'profile',
      headerName: t('Profile'),
      flex: 2,
    },
    {
      field: 'description',
      headerName: t('description'),
      flex: 3,
      valueFormatter: (value, row) =>
        value + ' ' + (row.expiresIn <= 0 ? 'Expired' : row.expiresIn > 0 && row.expiresIn <= 30 ? 'Expiring' : 'Soon'),
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      width: 86,
      sortable: false,
      // NOTE: This is a special global class that doesn't get mangled. SEE css file
      cellClassName: 'ptNotificationThreeDots',
      renderCell: (params) => (
        <div>
          <Button
            id="basic-button"
            aria-controls={openMenu ? 'bulk-actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={(event) => handleBulkActionClick(event, params.row)}
          >
            {/* @ts-expect-error these are valid props by the component definition */}
            <MoreVert size={'20'} sx={{ color: '#666' }} />
          </Button>
        </div>
      ),
    },
  ];

  const markAsViewed = async (item: ComplianceRow) => {
    dispatch(setNotificationStatus([item.id], 'Read'));
    setSelectedItem(item);
    handleBulkActionClose();
    await notificationService.markNotificationAsRead(item.id);
  };

  const goToProfile = (item: ComplianceRow) => {
    console.log('go to profile', item);
    setSelectedItem(item);
    handleBulkActionClose();
  };

  const clearSelection = () => setCheckedRows([]);

  return (
    <div className={css.DataGridCompliance}>
      <NotificationsComplianceMenu rows={complianceRows} selectedRowIds={checkedRows} clearSelection={clearSelection} />
      <Divider />
      {(displayRows?.length > 0 || isLoading) && (
        <DriftDataGrid
          rows={displayRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pagination
          pageSizeOptions={[10, 25]}
          getRowClassName={(params: GridRowClassNameParams<ComplianceRow>) => `row${params.row.status}`}
          getRowId={(row: ComplianceRow) => row.id}
          checkboxSelection
          rowSelectionModel={checkedRows}
          onRowSelectionModelChange={setCheckedRows}
          disableRowSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          disableColumnMenu
          sx={{
            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select': {
              fontSize: '14px',
            },
          }}
          loading={isLoading}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      )}
      {displayRows?.length === 0 && !isLoading && (
        <div className={css.emptyState}>
          <EmptyState
            icon={EmptyStateIcon}
            titleText={''}
            descriptionText={t('notifications_empty_state_desc')}
            haveCallback={false}
          />
        </div>
      )}
      <Menu
        id="bulk-actions-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleBulkActionClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => markAsViewed(selectedItem)}>
          <ListItemIcon>
            <VisibilityOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText>{t('marked_as_viewed')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => goToProfile(selectedItem)}>
          <ListItemIcon>
            <OpenInNewIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>{t('go_to_profile')}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
