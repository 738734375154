import { SORT } from 'constants/fuelConstants';
import { string } from 'prop-types';

declare const firebase;

const Config = require('Config');

let userLatitude = 0;
let userLongitude = 0;
let isLoaded = false;
let errorLoadingScript = false;

interface FirebaseConfigProps {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position: any) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        userLatitude = latitude;
        userLongitude = longitude;
      },
      () => {},
    );
  }
};

const getFirebaseModule = () => {
  return window['firebaseModuleName'] || 'TMS';
};

const loadScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.src = src;
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', () => {
      reject();
    });
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  });
};

export const init = async (firebaseConfig: FirebaseConfigProps) => {
  try {
    getLocation();

    await loadScript('https://www.gstatic.com/firebasejs/7.16.0/firebase-app.js');
    await loadScript('https://www.gstatic.com/firebasejs/7.16.0/firebase-analytics.js');

    isLoaded = true;
    firebase?.initializeApp(firebaseConfig);
  } catch (e) {
    errorLoadingScript = true;
    throw new Error(e.message);
  }
};

export default class FirebaseAnalytics {
  static get MODULES() {
    return {
      CREDIT: 'Credit',
      FACTORING: 'Factoring',
      FUEL: 'Fuel',
      LOADS: 'TMS',
      DISPATCH: 'Dispatch',
      MY_FLEET: 'My Fleet',
      RTSPRO: 'RTSPro',
      SHOPS: 'Shops',
      TRIP_MANAGEMENT: 'Trip Management',
      LOAD_MANAGEMENT: 'Load Management',
      COMPANY_INFO: 'Company Info',
      ENTERPRISE: 'Enterprise',
    };
  }

  static get PARAMETERS() {
    return {
      ACTION: 'action',
      BRANDS: 'brands',
      CITY: 'city',
      CLIENT_ID: 'client_id',
      CONFIRMATION_EMAIL: 'confirmation_email',
      CONTACT: 'contact',
      COUNT: 'count',
      DATE: 'date',
      DESTINATION: 'destination',
      DISCOUNT: 'discount',
      DRIVER: 'driver',
      ENDING_LOCATION: 'ending_location',
      FEATURED_STATIONS: 'featured_stations',
      FUEL_TRANSACTION_ID: 'fuel_transaction_id',
      GALLONS: 'gallons',
      HAS_FUNDING_INSTRUCTIONS: 'has_funding_instructions',
      HAS_NOTE: 'has_note',
      HIGHWAYS: 'highways',
      ID: 'Id',
      INVOICE_ID: 'invoice_id',
      INVOICE_NUMBER: 'invoice_number',
      ITEM_LIST_COUNT: 'item_list_count',
      LABEL: 'label',
      LAT: 'lat',
      LNG: 'lng',
      LOAD_ID: 'load_id',
      LOAD_ID_LIST: 'load_id_list',
      LOAD_NUMBER: 'load_number',
      MILEAGE: 'mileage',
      MODULE: 'module',
      NAME: 'name',
      PAGE: 'page',
      PAYMENT_RECEIVED_DATE: 'payment_received_date',
      PHONE: 'phone',
      PRICE: 'price',
      PO_NUMBER: 'po_number',
      PREVIOUS_STATUS: 'previous_status',
      PURCHASE_ID: 'purchase_id',
      SEARCH_NAME: 'search_name',
      SEARCH_TYPE: 'search_type',
      SEARCH_VALUE: 'searchValue',
      STATE: 'state',
      STATES: 'states',
      STARTING_LOCATION: 'starting_location',
      STATUS: 'status',
      STOP_ID: 'stop_id',
      STOPS: 'stops',
      TAB_SELECTED: 'tab_selected',
      TRAILER_NUMBER: 'trailer_number',
      TRANSACTION_DATE: 'transaction_date',
      TRANSACTION_TIME: 'transaction_time',
      TRIP_NO: 'trip_no',
      TRUCK: 'truck',
      TOTAL_LOADS: 'total_loads',
      TOTAL_AMOUNT: 'total_amount',
      TYPE: 'type',
      TYPES: 'types',
      USER_ID: 'UserID',
      USER_TYPE: 'userType',
      VALUE: 'value',
    };
  }

  static get EVENTS() {
    return {
      CHOOSE_LIBRARY: 'choose_library',
      CHOOSE_PDF: 'choose_pdf',
      CLICK: 'click',
      CLICK_MENU: 'click_menu',
      CREATE: 'create',
      DELETE: 'Delete',
      DETAILS_VIEW: 'Details_view',
      EDIT: 'edit',
      ENTER: 'enter',
      EULA_ACCEPTED: 'eula_accepted',
      EXIT: 'exit',
      EXPORT: 'export',
      FILTER: 'filter',
      FOCUSED: 'focused',
      PAGE_VIEW: 'page_view',
      RECEIVED: 'received',
      SAVE: 'save',
      SEARCH: 'search',
      SELECT: 'select',
      SET_FAVORITE: 'set_favorite',
      SORT: 'sort',
      SUBMIT: 'submit',
      TAB_SELECTED: 'tab_selected',
      TAKE_PHOTO: 'take_photo',
      UPLOAD: 'upload',
      EDIT_DETAILS: 'edit_details',
      SORT_BY: 'Sort By',
    };
  }

  static get LABELS() {
    return {
      ADD_DRIVER: 'Add Driver',
      ADD_STOP: 'Add Stop',
      ADD_TRUCK: 'Add Truck',
      ADD_NOTES: 'Add Notes',
      ADD_NOTES_TRAILER: 'Add Notes Trailer',
      ADD_NOTES_TRUCK: 'Add Notes Truck',
      ADD_NOTES_DRIVER: 'Add Notes Driver',
      AGING: 'Aging',
      AMOUNT: 'Amount',
      AVOID_TOLLS: 'Avoid Tolls',
      COMPANY_INFO: 'Company Info',
      CONTACT_NAME: 'Contact Name',
      CONTACT_PHONE_NUMBER: 'Contact Phone Number',
      CREATE_LOAD: 'Create Load',
      INVOICE_LOADS: 'Invoice Loads',
      DEBTOR: 'Debtor',
      DRIVER: 'Driver',
      TRUCK_OWNER: 'Truck Owner',
      BROKER: 'Broker',
      SHIPPER_RECEIVER: 'Shipper/Receiver',
      VENDOR: 'Vendor',
      OTHER: 'Other',
      DOCUMENT: 'Document',
      DOCUMENT_DRIVER: 'Document Driver',
      DOCUMENT_TRUCK: 'Document Truck',
      EDIT_LOAD: 'Edit Load',
      EMPTY_MILES: 'Empty Miles',
      EXPORT: 'Export',
      EXPORT_INVOICES: 'Export Invoices',
      FUEL: 'Fuel',
      HAZMAT: 'Hazmat',
      IFTA: 'IFTA',
      LOAD: 'Load',
      LOADS: 'Loads',
      LOAD_NUMBER: 'Load Number',
      LOADED_MILES: 'Loaded Miles',
      LOGOUT: 'Logout',
      MY_FLEET: 'My Fleet',
      EQUIPMENT: 'Equipment',
      OPEN_BORDERS: 'Open Borders',
      PAYMENT_DATE: 'Payment Date',
      PO_NUMBER: 'PO Number',
      PRINT: 'Print',
      PURCHASE_IFTA: 'Purchase IFTA',
      CREATE_IFTA: 'Create IFTA',
      CANCEL_PAYMENT: 'Cancel Payment',
      REMOVE_STOP: 'Remove Stop',
      SEARCH_VIN: 'Search VIN',
      SETTINGS: 'Settings',
      SKIP: 'Skip',
      SUBMIT_TO_RTS: 'Submit to RTS',
      TRAILER: 'Trailer',
      TRUCK: 'Truck',
      IFTA_FUEL_EDIT: 'IFTA Fuel Edit',
      IFTA_STOPS_EDIT: 'IFTA Stops Edit',
      ACQUIRE_PROTRANS: 'Acquire Protransport',
      SEARCH_NEW_DRIVER: 'Search New Driver',
      SEARCH_NEW_TRUCK: 'Search New Truck',
      SEARCH: 'Search',
      UPLOAD_LOGO: 'Upload Logo',
      INVOICE_SUMMARY_VIEW_DETAILS: 'Submission View Details',
      ADD_NEW: 'Add New',
      MORE_DETAILS: 'More Details',
      STATEMENTS: 'Statements',
      TRIP_MARKER_CLICK: 'Trip Marker Click',
      DISPATCH: 'Dispatch',
      REQUEST_UPGRADE: 'Request Upgrade',
      UPGRADE_PLAN: 'Upgrade Plan',
      START_INTEGRATION: 'Start Integration',
      STOP_INTEGRATION: 'Stop Integration',
      CREATE_STATEMENT: 'Create Statement',
      VIEW: 'View',
      EDIT: 'Edit',
      DETAILS: 'Details',
      DELETE: 'Delete',
      PARTNER_DROPDOWN: 'Partner Dropdown',
      STATEMENT_DATE: 'Statement Date',
      FUEL_ENTRIES: 'Fuel Entries',
      CANCEL: 'Cancel',
      SAVE: 'Save',
      CREATE_ENTRY: 'Create Entry',
      STATEMENT_RANGE: 'Statement Range',
      RATE: 'Rate',
      CREDITS: 'Credits',
      DEDUCTIONS: 'Deductions',
      COMMENTS: 'Comments',
      EMAIL: 'Email',
      JOYRIDE_VIEW: 'JoyRide View',
      JOYRIDE_SKIP: 'JoyRide Skip',
      JOYRIDE_STEP1: 'JoyRide 1/6 Company Info',
      JOYRIDE_STEP2: 'JoyRide 2/6 My Fleet',
      JOYRIDE_STEP3: 'JoyRide 3/6 Loads',
      JOYRIDE_STEP4: 'JoyRide 4/6 Invoice Loads',
      JOYRIDE_STEP5: 'JoyRide 5/6 Statements',
      JOYRIDE_STEP6: 'JoyRide 6/6 Dashboard- Get Started',
      DASHBOARD: 'Dashboard',
      REOPEN_INVOICE: 'Re-Open Invoice',
      MARK_INVOICE_AS_PAID: 'Mark Invoice As Paid',
      AMOUNT_PAID: 'Amount Paid',
      NON_FACTORED_INVOICES: 'Non-Factored Invoices',
      FACTORED_INVOICES: 'Factored Invoices',
      INVOICE_ROW: 'Invoice Row',
      MARK_AS_PAID: 'Mark As Paid',
      APPLY_PAYMENT: 'Apply Payment',
      UNDO: 'Undo',
      VIEW_INVOICE: 'View Invoice',
      COPY_LOAD: 'Copy Load',
      WEX_RTSCS_ON: 'WEX_RTSCS on',
      WEX_RTSCS_OFF: 'WEX_RTSCS off',
      IFTA_RERUN_BUTTON: 'Rerun Report Button',
      IFTA_RERUN_CONFIRMATION: 'Rerun Report Confirmation',
      IFTA_TRUCK_RECALCULATE: 'Truck Recalculated',
      SHOW_MORE: 'Show More',
      SHOW_LESS: 'Show Less',
      SPLIT_PAYOUT: 'Split Payout',
      SUBMIT_PURCHASE: 'Submit Purchase',
      PAY_RATE_VIEW_HISTORY: 'View history of rate changes',
      PAY_RATE_RESET_TO_DEFAULT: 'Reset to Default Pay Rate',
      PAY_RATE_SET_DEFAULT: 'Set Default Pay Rate',
      ADD_TRAILER: 'Add Trailer',
      STATUS: 'status',
      VIEW_TRIP_DETAILS: 'View Trip Details',
      ASSIGN_DIFFERENT_DRIVER: 'Assign to a Different Driver',
      ASSIGNED: 'Assigned',
    };
  }

  static get PAGES() {
    return {
      COMPANY: 'Company Info',
      FUEL: 'Fuel Entry',
      IFTA: 'IFTA',
      LOAD_EDIT: getFirebaseModule() === 'Enterprise' ? 'Loads' : 'Load_Edit',
      LOAD_LIST: getFirebaseModule() === 'Enterprise' ? 'Loads' : 'Load_List',
      LOAD_COPY_DIALOG: 'Load Copy Dialog',
      LOADS: 'Loads',
      MY_FLEET: 'My_Fleet',
      INVOICE_SUMMARY: 'Invoice_Summary',
      INVOICE_EMAIL_MODAL: 'Invoice_Email_Modal',
      INVOICE_EMAIL_CANCEL_MODAL: 'Invoice_Email_Cancel_Modal',
      MENU: 'Menu',
      SETTINGS: 'Settings',
      SUB_HEADER: 'Sub_Header',
      AGING: 'Aging',
      DRIVER_EDIT_MODAL: 'Driver Edit Modal',
      TRUCK_EDIT_MODAL: 'Truck_Edit_Modal',
      SUBMISSION_FAILED_SNACKBAR: 'Submission_Failed_Snackbar',
      STATEMENTS: 'Statements',
      STATEMENT_EDIT: 'Statement Edit',
      STATEMENT_VIEWER: 'Statements Viewer',
      MARKETING_PLANS: 'Marketing Plans',
      JOYRIDE: 'JoyRide',
      DISPATCH: 'Dispatch',
      DASHBOARD: 'Dashboard',
      PARTNERS: 'Partners',
      PARTNERS_EDIT: 'Partners Edit',
      ADD_NEW_PARTNER: 'Add New Partner',
      ADD_EQUIPMENT: 'Add Equipment',
      EQUIPMENT: 'Equipment',
      PAYOUTS: 'Payouts',
      TRIP_DETAILS: 'Trip Details',
      ASSIGN_TRIPS: 'Assign Trips',
    };
  }

  static setCurrentScreen(name: string) {
    firebase?.analytics().setCurrentScreen(name);
  }

  static setUser(userId: string, userType?: string) {
    firebase?.analytics().setUserProperties({ [FirebaseAnalytics.PARAMETERS.USER_ID]: userId });
    firebase?.analytics().setUserId(userId);
    if (userType) {
      firebase?.analytics().setUserProperties({
        [FirebaseAnalytics.PARAMETERS.USER_TYPE]: userId,
      });
    }
  }

  static logPageView(page: string) {
    firebase?.analytics().logEvent(FirebaseAnalytics.EVENTS.PAGE_VIEW, {
      module: FirebaseAnalytics.MODULES.LOADS,
      page,
    });
  }

  static sendEvent(event: string, module: string, page: string, customObject?: Object) {
    const payload = customObject || {};
    payload['module'] = window['firebaseModuleName'] || module;
    payload['page'] = page;

    firebase?.analytics()?.logEvent(event, payload);
  }
}
