import React from 'react';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/appActions';
import { connect, useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/configureStore';
import { FeatureFlags } from '../../enums/featureFlagEnum';
import MaintenanceAlertDialog from '../../components/alerts/maintenance-alert-dialog';
import css from './federated-app.scss';
import FinancialService from '../../services/financial.service';
import Loadable from 'react-loadable';
import LoadingSpinner from '../../components/core/loading-spinner';
import { useTranslation } from 'react-i18next';
import Layout from '../../federation/rtspro/router-layout';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import ProtectedPage from './protected-page';
import RequestUpgrade from '../../components/company-plan/request-upgrade';
import DispatchTest from '../../components/dispatch/dispatch-test';

const Dashboard = Loadable({
  loader: () => import('ptmodules/Dashboard'),
  loading: LoadingSpinner,
});

const NewDashboard = Loadable({
  loader: () => import('../../components/dashboard/dashboard'),
  loading: LoadingSpinner,
});

const Loads = Loadable({
  loader: () => import('../../components/loads/loads-premium'),
  loading: LoadingSpinner,
});

const LoadsStandard = Loadable({
  loader: () => import('../../components/loads/loads-basic'),
  loading: LoadingSpinner,
});

const AdvancedLoads = Loadable({
  loader: () => import('../../components/advanced-loads/advanced-loads'),
  loading: LoadingSpinner,
});

const DispatchContainer = Loadable({
  loader: () => import('../../components/dispatch/dispatch-container'),
  loading: LoadingSpinner,
});

const AgingReport = Loadable({
  loader: () => import('../../components/aging/aging-report'),
  loading: LoadingSpinner,
});

const AgingReportStatic = Loadable({
  loader: () => import('../../components/aging/aging-report-static'),
  loading: LoadingSpinner,
});

const FuelEntry = Loadable({
  loader: () => import('../../components/fuel/fuel-entry'),
  loading: LoadingSpinner,
});

const IFTAReports = Loadable({
  loader: () => import('../../components/ifta/ifta-reports'),
  loading: LoadingSpinner,
});

const CompanyInfo = Loadable({
  loader: () => import('../../components/company-info/company-info'),
  loading: LoadingSpinner,
});

const MyFleet = Loadable({
  loader: () => import('../../components/my-fleet/my-fleet'),
  loading: LoadingSpinner,
});

const Equipment = Loadable({
  loader: () => import('../../components/equipments/equipment'),
  loading: LoadingSpinner,
});

const MyFleetBasic = Loadable({
  loader: () => import('../../components/my-fleet/my-fleet-basic'),
  loading: LoadingSpinner,
});

const Statements = Loadable({
  loader: () => import('../../components/statements/statements-premium'),
  loading: LoadingSpinner,
});

const StatementsBasic = Loadable({
  loader: () => import('../../components/statements/statements-basic'),
  loading: LoadingSpinner,
});

const Partners = Loadable({
  loader: () => import('../../components/partners/partners'),
  loading: LoadingSpinner,
});

const Router = ({
  isAuthed,
  features,
  permissions,
  clientNumber,
  factoringClientSelector,
  header,
  user,
  appTier,
}: any) => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const maintenanceAlert = useSelector((state: any) => state.app.maintenanceAlert);
  const bypassMaintenance = features && features.includes(FeatureFlags.MAINTENANCE_BYPASS);
  const isFinancial = !!permissions?.find((p: any) => p.name === 'invoicemanager.User' && p.status === 'active');
  const pageContainerRef = React.useRef(null);
  const reduxClientNumber = useSelector((state: any) => state.app.clientNumber);
  const sideNavigationFF = features.includes(FeatureFlags.SIDE_NAVIGATION);
  const headerHeight = sideNavigationFF && isFinancial ? 30 : 0;
  const proTransLite = !!permissions?.find((p: any) => p.name === 'ProTransportWeb.user' && p.status === 'active');
  const hasDispatchReadEnabled = !!permissions?.find(
    (p: any) => p.name === 'tms.dispatch.read' && p.status === 'active',
  );
  const statementsElement = proTransLite ? <Statements /> : <StatementsBasic />;
  const isDispatchFFEnabled = features.includes(FeatureFlags.DISPATCH);
  const advancedLoads = features.includes(FeatureFlags.ADVANCED_LOADS);
  const isNewPTEnterpriseDashboard = features.includes(FeatureFlags.IS_NEW_PT_ENTERPRISE_DASHBOARD);
  const isNewPTEnterpriseEquipmentPartners = features.includes(FeatureFlags.IS_NEW_PT_ENTERPRISE_EQUIPMENT_PARTNERS);
  const advancedLoadEntryBetaNavigation = features.includes(FeatureFlags.ADVANCED_LOADS_BETA_NAVIGATION);
  const reduxClientNumberUpdated = clientNumber === reduxClientNumber;
  const userCanReadLoads = !!permissions?.find((p: any) => p.name === 'tms.loads.read' && p.status === 'active');

  const getAgingComponent = () => {
    if (isFinancial && !proTransLite) {
      return <AgingReportStatic />;
    } else {
      return <AgingReport hasProTransLite={proTransLite} isFinancial={isFinancial} />;
    }
  };

  const getLoadComponent = () => {
    if (advancedLoads && !advancedLoadEntryBetaNavigation) {
      return <AdvancedLoads hasProTransLite={proTransLite} isFinancial={isFinancial} appTier={appTier} />;
    } else if (proTransLite) {
      return <Loads />;
    } else {
      return <LoadsStandard />;
    }
  };

  let routerChildren: RouteObject[] = [
    {
      path: '/loads/ifta',
      element: <IFTAReports />,
    },
    {
      path: '/loads/reports/aging',
      element: getAgingComponent(),
    },
    {
      path: '/loads/fuel',
      element: <FuelEntry />,
    },
    {
      path: '/loads/myfleet',
      element: proTransLite ? <MyFleet ptEnterprise /> : <MyFleetBasic />,
    },
    {
      path: '/loads/equipment',
      element: <Equipment />,
    },
    {
      path: '/loads/company',
      element: <CompanyInfo />,
    },
    {
      path: '/loads/requestupgrade',
      element: (
        <div className={css.requestUpgrade}>
          <RequestUpgrade hasProTransLite={proTransLite} />
        </div>
      ),
    },
    {
      path: '/loads/statements',
      element: statementsElement,
    },
    {
      path: '/loads/dispatch-test',
      element: (
        <ProtectedPage
          element={<DispatchTest />}
          isAuthorized={isDispatchFFEnabled && hasDispatchReadEnabled}
          reroutePath="/loads"
        />
      ),
    },
    {
      path: '/loads/dispatch',
      element: (
        <ProtectedPage
          element={
            <div className={css.dispatch}>
              <DispatchContainer />
            </div>
          }
          isAuthorized={isDispatchFFEnabled && hasDispatchReadEnabled}
          reroutePath="/loads"
        />
      ),
    },
    {
      path: '/loads/dashboard',
      element: (
        <ProtectedPage
          element={
            <div className={css.dashboard}>
              {isNewPTEnterpriseDashboard ? <NewDashboard /> : <Dashboard i18n={i18n} hostApp={'load-management'} />}
            </div>
          }
          isAuthorized={proTransLite}
          reroutePath="/loads"
        />
      ),
    },
    {
      path: '/loads/loads_new',
      element: (
        <ProtectedPage
          element={<AdvancedLoads hasProTransLite={proTransLite} isFinancial={isFinancial} appTier={appTier} />}
          reroutePath="/loads"
          isAuthorized={advancedLoadEntryBetaNavigation && userCanReadLoads}
        />
      ),
    },
  ];

  if (isNewPTEnterpriseEquipmentPartners) {
    routerChildren.push({
      path: '/loads/partners',
      element: (
        <ProtectedPage
          element={<Partners />}
          reroutePath="/loads"
          isAuthorized={isNewPTEnterpriseEquipmentPartners}
        ></ProtectedPage>
      ),
    });
  }

  routerChildren.push({
    path: '/loads',
    element: getLoadComponent(),
  });

  React.useEffect(() => {
    dispatch(appActions.getMaintenanceAlert());
  }, []);

  React.useEffect(() => {
    dispatch(appActions.setClientNumber(clientNumber));
    updateFinancialUser();
  }, [clientNumber]);

  const updateFinancialUser = async () => {
    const financial = !!user?.permissions.find(
      (permission) => permission.name === 'invoicemanager.User' && permission.status.toLowerCase() === 'active',
    );
    if (financial && user?.email) {
      const financialUser = await new FinancialService().getUserById(user?.email);
      if (financialUser) dispatch(appActions.setFinancialUser(financialUser));
    }
  };

  const router = createBrowserRouter([
    {
      element: <Layout proTransLite={proTransLite} features={features} header={header}></Layout>,
      children: routerChildren,
    },
  ]);

  if (reduxClientNumberUpdated) {
    return (
      <>
        {isAuthed && (
          <div style={{ height: 'calc(100% + 2px)', marginTop: '-2px' }}>
            {maintenanceAlert.visible && !bypassMaintenance && (
              <MaintenanceAlertDialog maintenanceAlert={maintenanceAlert} />
            )}
            {sideNavigationFF && isFinancial && <div>{factoringClientSelector}</div>}
            <div
              className={css.pageContainer}
              ref={pageContainerRef}
              style={{
                height: `calc(100% - ${headerHeight}px)`,
              }}
            >
              <RouterProvider router={router} />
            </div>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

function mapStateToProps(state) {
  const { app } = state;
  return {
    isAuthed: !!app.token && !!app.user && !!app.tmsCompany && !!app.company,
    permissions: app.user?.permissions,
    appTier: app.user?.applicationTier,
    user: app.user,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(appActions, dispatch) };
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Router);
