export enum FeatureFlags {
  LM_TRIPS = 'TMS-1892-WEB',
  MAINTENANCE_BYPASS = 'TMS-Maintenance',
  PT_BASIC_TRIAL = 'CPUA-4769-WEB',
  FACTORING_MAINTENANCE = 'TMS-FACTORING-MAINTENANCE',
  DISPATCH = 'TMS-4499-WEB',
  SIDE_NAVIGATION = 'FUEL-4073',
  WEX_INTEGRATION_AUTOMATION = 'TMS-4211-WEB',
  ADVANCED_LOADS = 'f6345c6d57db87fda0e6fcabe4fa3f39566dde889ee35b98d2a765f951becc08',
  NEW_DEBTOR_SEARCH = '4bf6748daf88aff2adcdaedfef627572c3d380ff8f1554e6e2478cc97b8e7006',
  IS_NEW_PT_ENTERPRISE_DASHBOARD = '1a1bc6f8c0731d1b6ff7325b5b9690af78d2ba1a5d66864bb97057c96e929175',
  IS_NEW_PT_ENTERPRISE_EQUIPMENT_PARTNERS = 'c40bf24ae94eae9ad4a62248087eaa85c54e1b562e950be3c3beccd24d967498',
  DISPATCH_PREMIUM = '4f18635fa376897930f6bf845dd43aeb3718f2cc3cf85dd52c052f2ea1107cea',
  PAYOUT = 'dffe06abfcf829bfeb5f579a3702c35d4a2fec4e984fca4e62d85b62c859b5d2',
  SHARED_SCHEDURE_GRID = 'ff6785ccf08df27415a555db923e3876cd550596d25be1a21274270b1505d9a2',
  DRIVER_PAY_RATES = '3304cc9de0ddc431b793be10d7ae2259077b560967b4fdb938602abd08e837d4',
  SIDE_NAVIGATION_FEEDBACK = '4143dad9033ef4f13d896d6f41c1595faa7e5225fb0d78c29dbacd69dd54464e',
  ADVANCED_LOADS_BETA_NAVIGATION = '64812a2bc408338156e701a0976c0c68f5f6eb6739a1867af4829904b66ffbbf',
  UPDATE_INVOICE_PRINTOUT = 'e21b2dd7e6fa2413f2f4b567cf215b10ec8989a1c301e503209053bb8c2c1630',
  PT_UPGRADES = 'ab9e4ed76cea751e2e41ce9d697508869a73df9fde5869571d3bec9de4ed6ad4',
  NOTIFICATIONS_SIDE_DRAWER = 'd73c8a478cd015fe6d08ba30096fbe45d0f2ab2f4ad94c344fd9c80febe67de0',
  SAVED_DEDUCTIONS = '8b091b1367a45b51b9ad99995293be519c81bb5d89b71ab75cd6ff88dc197341',
}
