import { Box, Drawer } from 'drift-ui';
import React from 'react';
import css from './styles/notifications-drawer.scss';
import NotificationsDrawerContent from './notifications-drawer-content';
import NotificationsDrawerHeader from './notifications-drawer-header';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

interface NotificationDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  drawerTab?: string;
}

export default function NotificationsDrawer({ open, toggleDrawer, drawerTab }: Readonly<NotificationDrawerProps>) {
  const [tab, setTab] = React.useState(drawerTab);
  const { t } = useTranslation();
  const handleTabClick = (tab: string) => {
    setTab(tab);
  };

  const StyledDrawer = styled(Drawer)({
    '& .MuiDataGrid-row.rowUnread': {
      backgroundColor: '#EAF7FF',
      '&:hover, &.Mui-selected': {
        backgroundColor: '#E4F2FD',
      },
    },
  });

  return (
    <StyledDrawer open={open} anchor={'right'} onClose={toggleDrawer}>
      <Box sx={{ height: '80%', width: 670, padding: '24px' }}>
        <div className={css.header}>{t('notifications')}</div>
        <Close className={css.close} onClick={toggleDrawer} />
        <NotificationsDrawerHeader tab={tab} handleTabClick={handleTabClick} />
        <NotificationsDrawerContent tab={tab} />
      </Box>
    </StyledDrawer>
  );
}
