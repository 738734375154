import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Country, StateProvince } from '../types/Country';
import * as equipmentService from '../services/equipmentService';
import { FuelType } from '../types/FuelType';
import { Truck } from '../types/Truck';
import { BusinessPartner } from '../types/BusinessPartner';
import { SnackBarConfig } from '../types/SnackBarConfig';
import { TmsCompany } from '../types/TmsCompany';
import { Trailer } from '../types/Trailer';
import { TrailerType } from '../types/TrailerType';
import { TrailerDoorType } from '../types/TrailerDoorType';
import { EquipmentMake } from '../types/EquipmentMake';
import { RootState } from '../../../redux/configureStore';

export interface EquipmentsState {
  tmsCompany?: TmsCompany;
  loading?: boolean;
  showSnackBar: boolean;
  stateList?: StateProvince[];
  snackBarConfig: SnackBarConfig;
  countries?: Country[];
  fuelTypes?: FuelType[];
  trailerTypes?: TrailerType[];
  trailerDoorTypes?: TrailerDoorType[];
  trailerMakes?: EquipmentMake[];
  trucks?: Truck[];
  trailers?: Trailer[];
  businessPartners?: BusinessPartner[];
}

const initialState: EquipmentsState = {
  tmsCompany: undefined,
  loading: false,
  showSnackBar: false,
  stateList: undefined,
  snackBarConfig: { message: '', type: undefined },
  countries: undefined,
  fuelTypes: undefined,
  trailerTypes: undefined,
  trailerDoorTypes: undefined,
  trailerMakes: undefined,
  trucks: undefined,
  trailers: undefined,
  businessPartners: undefined,
};

interface Patch {
  id: number;
  patchInfo: { key: string; value: any }[];
}

export const getTmsCompany = createAsyncThunk('equipments/getTmsCompany', async (_, thunkApi) => {
  const { data } = await equipmentService.getTmsCompany();
  return data;
});

export const getFuelTypes = createAsyncThunk('equipments/getFuelTypes', async () => {
  const { data } = await equipmentService.getFuelTypes();
  return data;
});

export const getCountries = createAsyncThunk('equipments/getCountries', async () => {
  const { data } = await equipmentService.getCountries();
  return data;
});

export const getTrucks = createAsyncThunk('equipments/getTrucks', async () => {
  const { data } = await equipmentService.getTrucks();
  return data;
});

export const getTrailerTypes = createAsyncThunk('equipments/getTrailerTypes', async () => {
  const { data } = await equipmentService.getTrailerTypes();
  return data;
});

export const getTrailerDoorTypes = createAsyncThunk('equipments/getTrailerDoorTypes', async () => {
  const { data } = await equipmentService.getTrailerDoorTypes();
  return data;
});

export const getTrailerMakes = createAsyncThunk('equipments/getTrailerMakes', async () => {
  const { data } = await equipmentService.getEquipmentMakes('trailer');
  return data;
});

export const getTrailers = createAsyncThunk('equipments/getTrailers', async () => {
  const { data } = await equipmentService.getTrailers();
  return data;
});

export const getBusinessPartners = createAsyncThunk('equipments/getBusinessPartners', async () => {
  const { data } = await equipmentService.getBusinessPartners();
  return data;
});

export const initData = createAsyncThunk('equipments/initData', async (_, thunkApi) => {
  thunkApi.dispatch(getFuelTypes());
  thunkApi.dispatch(getTrailerTypes());
  thunkApi.dispatch(getTrailerDoorTypes());
  thunkApi.dispatch(getTrailerMakes());
  thunkApi.dispatch(getCountries());
  thunkApi.dispatch(getTrucks());
  thunkApi.dispatch(getBusinessPartners());
  thunkApi.dispatch(getTmsCompany());
  thunkApi.dispatch(getTrailers());
});

export const updateTruck = createAsyncThunk('equipments/updateTruck', async (payload: Patch) => {
  const { id, patchInfo } = payload;
  return await equipmentService.updateTruck(id, patchInfo);
});

export const updateTrailer = createAsyncThunk('equipments/updateTrailer', async (payload: Patch) => {
  const { id, patchInfo } = payload;
  return await equipmentService.updateTrailer(id, patchInfo);
});

export const equipmentSlice = createSlice({
  name: 'equipments',
  initialState,
  reducers: {
    setLoading: (state: EquipmentsState, action: PayloadAction<boolean | undefined>) => {
      state.loading = action.payload;
    },
    setShowSnackbar: (state: EquipmentsState, action: PayloadAction<boolean>) => {
      state.showSnackBar = action.payload;
    },
    setSnackbarConfig: (state: EquipmentsState, action: PayloadAction<SnackBarConfig>) => {
      state.snackBarConfig = action.payload;
    },
    setAndShowSnackbar: (state: EquipmentsState, action: PayloadAction<SnackBarConfig>) => {
      state.snackBarConfig = action.payload;
      state.showSnackBar = true;
    },
    updateTruckInList: (state: EquipmentsState, action: PayloadAction<Truck>) => {
      const truck = { ...action.payload, equipmentType: 'truck' };
      state.trucks = state.trucks?.map((t) => {
        if (t.id === truck.id) {
          return truck;
        }
        return t;
      });
    },
    addTruckToList: (state: EquipmentsState, action: PayloadAction<Truck>) => {
      const truck = { ...action.payload, equipmentType: 'truck' };
      state.trucks = [truck, ...(state.trucks as Truck[])];
    },
    updateTrailerInList: (state: EquipmentsState, action: PayloadAction<Trailer>) => {
      const types = state.trailerTypes;
      const doorTypes = state.trailerDoorTypes;
      const type = types?.find((ty) => ty.id === action.payload.trailerTypeId);
      const doorType = doorTypes?.find((d) => d.id === action.payload.trailerDoorTypeId);
      const trailer = {
        ...action.payload,
        trailerTypeName: type?.name,
        trailerDoorTypeName: doorType?.name,
        equipmentType: 'trailer',
      };
      state.trailers = state.trailers?.map((t) => {
        if (t.id === trailer.id) {
          return trailer;
        }
        return t;
      });
    },
    addTrailerToList: (state: EquipmentsState, action: PayloadAction<Trailer>) => {
      const types = state.trailerTypes;
      const doorTypes = state.trailerDoorTypes;
      const type = types?.find((ty) => ty.id === action.payload.trailerTypeId);
      const doorType = doorTypes?.find((d) => d.id === action.payload.trailerDoorTypeId);
      const trailer = {
        ...action.payload,
        trailerTypeName: type?.name,
        trailerDoorTypeName: doorType?.name,
        equipmentType: 'trailer',
      };
      state.trailers = [trailer, ...(state.trailers as Trailer[])];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTmsCompany.fulfilled, (state, action) => {
        state.tmsCompany = action.payload;
      })
      .addCase(getTmsCompany.rejected, (state, _action) => {
        state.snackBarConfig = {
          message: 'Error Loading TMS Company',
          type: 'error',
        };
        state.showSnackBar = true;
      })
      .addCase(getFuelTypes.fulfilled, (state, action) => {
        state.fuelTypes = action.payload;
      })
      .addCase(getFuelTypes.rejected, (state, _action) => {
        state.snackBarConfig = {
          message: 'Error Loading Fuel Types',
          type: 'error',
        };
        state.showSnackBar = true;
      })
      .addCase(getTrailerTypes.fulfilled, (state, action) => {
        state.trailerTypes = action.payload;
      })
      .addCase(getTrailerTypes.rejected, (state, _action) => {
        state.snackBarConfig = {
          message: 'Error Loading Trailer Types',
          type: 'error',
        };
        state.showSnackBar = true;
      })
      .addCase(getTrailerDoorTypes.fulfilled, (state, action) => {
        state.trailerDoorTypes = action.payload;
      })
      .addCase(getTrailerDoorTypes.rejected, (state, _action) => {
        state.snackBarConfig = {
          message: 'Error Loading Trailer Door Types',
          type: 'error',
        };
        state.showSnackBar = true;
      })
      .addCase(getTrailerMakes.fulfilled, (state, action) => {
        state.trailerMakes = action.payload;
      })
      .addCase(getTrailerMakes.rejected, (state, _action) => {
        state.snackBarConfig = {
          message: 'Error Loading Trailer Makes',
          type: 'error',
        };
        state.showSnackBar = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
        state.stateList = action.payload[0].stateProvinces;
      })
      .addCase(getCountries.rejected, (state, _action) => {
        state.snackBarConfig = {
          message: 'Error Loading Countries',
          type: 'error',
        };
        state.showSnackBar = true;
      })
      .addCase(getTrucks.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(getTrucks.fulfilled, (state, action) => {
        state.loading = false;
        const trucks = action.payload.map((t) => {
          return { ...t, equipmentType: 'truck' };
        });
        state.trucks = trucks;
      })
      .addCase(getTrucks.rejected, (state, _action) => {
        state.loading = false;
        state.snackBarConfig = {
          message: 'Error Loading Trucks',
          type: 'error',
        };
        state.showSnackBar = true;
      })
      .addCase(getTrailers.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(getTrailers.fulfilled, (state, action) => {
        state.loading = false;
        const types = state.trailerTypes;
        const doorTypes = state.trailerDoorTypes;
        const trailers = action.payload.map((t) => {
          const type = types?.find((ty) => ty.id === t.trailerTypeId);
          const doorType = doorTypes?.find((d) => d.id === t.trailerDoorTypeId);
          return { ...t, trailerTypeName: type?.name, trailerDoorTypeName: doorType?.name, equipmentType: 'trailer' };
        });
        state.trailers = trailers;
      })
      .addCase(getTrailers.rejected, (state, _action) => {
        state.loading = false;
        state.snackBarConfig = {
          message: 'Error Loading Trailers',
          type: 'error',
        };
        state.showSnackBar = true;
      })
      .addCase(updateTruck.rejected, (state, _action) => {
        state.snackBarConfig = {
          message: 'Error updating truck',
          type: 'error',
        };
        state.showSnackBar = true;
      })
      .addCase(updateTrailer.rejected, (state, _action) => {
        state.snackBarConfig = {
          message: 'Error updating trailer',
          type: 'error',
        };
        state.showSnackBar = true;
      })
      .addCase(getBusinessPartners.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(getBusinessPartners.fulfilled, (state, action) => {
        state.loading = false;
        state.businessPartners = action.payload;
      })
      .addCase(getBusinessPartners.rejected, (state, _action) => {
        state.loading = false;
        state.snackBarConfig = {
          message: 'Error Loading Business Partners',
          type: 'error',
        };
        state.showSnackBar = true;
      });
  },
});

export default equipmentSlice.reducer;
export const getLoading = (state: RootState) => state.equipments.loading;
export const getTruckList = (state: RootState) => state.equipments.trucks;
export const getTruckFromList = (id: number) => (state: RootState) => state.equipments.trucks?.find((t) => t.id === id);
export const getTrailerList = (state: RootState) => state.equipments.trailers;
export const getTrailerFromList = (id: number) => (state: RootState) =>
  state.equipments.trailers?.find((t) => t.id === id);
export const getSnackbarConfig = (state: RootState) => state.equipments.snackBarConfig;
export const getShowSnackbar = (state: RootState) => state.equipments.showSnackBar;
export const getTmsCompanyId = (state: RootState) => state.equipments.tmsCompany?.id;
export const getStateList = (state: RootState) => state.equipments.stateList;
export const getFuelTypeList = (state: RootState) => state.equipments.fuelTypes;
export const getTrailerTypeList = (state: RootState) => state.equipments.trailerTypes;
export const getTrailerDoorTypeList = (state: RootState) => state.equipments.trailerDoorTypes;
export const getTrailerMakeList = (state: RootState) => state.equipments.trailerMakes;
export const filterPartnerByType = (partnerType: number) => (state: any) => {
  return state.equipments.businessPartners.reduce((a: BusinessPartner[], c: BusinessPartner) => {
    if (c.types) {
      const isItem = c.types.find((t) => t.id === partnerType);
      return isItem ? [...a, c] : a;
    }
    return a;
  }, []);
};
export const {
  setLoading,
  setShowSnackbar,
  setSnackbarConfig,
  setAndShowSnackbar,
  updateTruckInList,
  addTruckToList,
  updateTrailerInList,
  addTrailerToList,
} = equipmentSlice.actions;
