import { AdvancedLoadItem } from 'interfaces/advanced-load-item';
import * as AdvancedLoadsConstants from '../../constants/advancedLoadsConstants';
import { LoadStatus } from '../../enums/load-status';
import { AdvancedLoadsSearchFilter } from '../../interfaces/advanced-loads-search-filter';
import { PaginationData } from '../../interfaces/pagination-data';
import { AdvancedLoadsSummary } from '../../interfaces/advanced-loads-summary';

export interface InvoiceRequestToken {
  loadId: number;
  invoiceRequestToken: number;
  processing: boolean;
}

export interface FinishedInvoiceStatus {
  loadId: number;
  completed: string[];
  failed: string[];
}

export interface SnackBarConfig {
  open: boolean;
  message: string;
  type: 'error' | 'info' | 'success' | undefined;
  time: number;
  action: () => void;
  width: string;
}

export const initialState = {
  refreshedLoad: {} as AdvancedLoadItem,
  load: {} as AdvancedLoadItem,
  invoiceRequests: [] as InvoiceRequestToken[],
  displayedLoads: [] as AdvancedLoadItem[],
  pagination: {} as PaginationData,
  summary: {} as AdvancedLoadsSummary,
  searchFilters: {
    statusFilters: [LoadStatus.All],
    sortBy: 'Date',
    sortAscending: false,
    startDate: null,
    endDate: null,
    filterText: '',
    pageSize: 25,
    pageNumber: 1,
  } as AdvancedLoadsSearchFilter,
  displayLoadingIndicator: true,
  loadCreated: false,
  newCreatedLoads: [] as AdvancedLoadItem[],
  snackbarConfig: {} as SnackBarConfig,
  finishedInvoiceStatus: [] as FinishedInvoiceStatus[],
};

export default function advancedLoadsReducer(state = initialState, action: { type: string; payload: any }) {
  let newState = state;

  switch (action.type) {
    case AdvancedLoadsConstants.SET_PAGINATION:
      return { ...newState, pagination: action.payload };
    case AdvancedLoadsConstants.SET_DISPLAYED_LOADS:
      return { ...newState, displayedLoads: action.payload };
    case AdvancedLoadsConstants.SET_LOAD_ITEM_CHECKED: {
      const { loadId, isChecked } = action.payload;
      const displayedLoads = newState.displayedLoads.map(load => {
        if (load.id === loadId) {
          return { ...load, isChecked };
        }
        return load;
      });
      return { ...newState, displayedLoads };
    }
    case AdvancedLoadsConstants.CHECK_ALL_LOAD_ITEMS: {
      const isChecked = action.payload;
      const displayedLoads = newState.displayedLoads.map(load => {
        return { ...load, isChecked };
      });
      return { ...newState, displayedLoads };
    }
    case AdvancedLoadsConstants.SET_SUMMARY:
      return { ...newState, summary: action.payload };
    case AdvancedLoadsConstants.SET_STATUS_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, statusFilters: action.payload } };
    case AdvancedLoadsConstants.SET_SORT_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, ...action.payload } };
    case AdvancedLoadsConstants.SET_DATE_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, ...action.payload } };
    case AdvancedLoadsConstants.SET_TEXT_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, filterText: action.payload } };
    case AdvancedLoadsConstants.SET_PAGE_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, pageNumber: action.payload } };
    case AdvancedLoadsConstants.SET_PAGE_SIZE_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, pageSize: action.payload } };
    case AdvancedLoadsConstants.SET_DISPLAY_LOADING_INDICATOR:
      return { ...newState, displayLoadingIndicator: action.payload };
    case AdvancedLoadsConstants.ADD_PLACEHOLDER_LOAD:
      return { ...newState, newCreatedLoads: [...newState.newCreatedLoads, action.payload] };
    case AdvancedLoadsConstants.SET_LOAD_CREATED:
      return { ...newState, loadCreated: action.payload };
    case AdvancedLoadsConstants.RESET_SEARCH_FILTERS:
      return { ...newState, searchFilters: action.payload };
    case AdvancedLoadsConstants.SET_LOAD_MESSAGE_STATUS: {
      return {
        ...newState,
        displayedLoads: newState.displayedLoads.map(item =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item,
        ),
      };
    }
    case AdvancedLoadsConstants.ADD_LOAD_FOR_INVOICING:
      return { ...newState, invoiceRequests: [...newState.invoiceRequests, action.payload] };
    case AdvancedLoadsConstants.CLEAR_LOAD_FOR_INVOICING:
      return {
        ...newState,
        invoiceRequests: newState.invoiceRequests.filter(x => x.invoiceRequestToken !== action.payload),
      };
    case AdvancedLoadsConstants.REFRESH_LOAD_FOR_INVOICING: {
      const { invoiceRequestToken, processing } = action.payload;
      const tempRequests = [...newState.invoiceRequests];
      const foundRequest = tempRequests.find(x => x.invoiceRequestToken === invoiceRequestToken);
      foundRequest.processing = processing;
      return { ...newState, invoiceRequests: tempRequests };
    }
    case AdvancedLoadsConstants.SET_DOWNLOAD_FLAG: {
      const loadIndex = newState.displayedLoads.findIndex(l => l.id === action.payload.loadId);
      if (loadIndex !== -1) {
        const updatedLoad = {
          ...newState.displayedLoads[loadIndex],
          canDownloadInvoice: action.payload.flag,
        };
        return {
          ...newState,
          displayedLoads: [
            ...newState.displayedLoads.slice(0, loadIndex),
            updatedLoad,
            ...newState.displayedLoads.slice(loadIndex + 1),
          ],
        };
      }
      return newState;
    }
    case AdvancedLoadsConstants.ADD_FINISHED_INVOICE_STATUS: {
      const { finishedStatus } = action.payload;
      const existingItemIndex = newState.finishedInvoiceStatus.findIndex(item => item.loadId === finishedStatus.loadId);
      if (existingItemIndex !== -1) {
        const updatedItem = {
          ...newState.finishedInvoiceStatus[existingItemIndex],
          completed: [...newState.finishedInvoiceStatus[existingItemIndex].completed, finishedStatus.completed],
          failed: [...newState.finishedInvoiceStatus[existingItemIndex].failed, finishedStatus.failed],
        };
        const updatedFinishedInvoiceStatus = [
          ...newState.finishedInvoiceStatus.slice(0, existingItemIndex),
          updatedItem,
          ...newState.finishedInvoiceStatus.slice(existingItemIndex + 1),
        ];
        return { ...newState, finishedInvoiceStatus: updatedFinishedInvoiceStatus };
      } else {
        return { ...newState, finishedInvoiceStatus: [...newState.finishedInvoiceStatus, finishedStatus] };
      }
    }
    case AdvancedLoadsConstants.CLEAR_FINISHED_INVOICE_STATUS: {
      return { ...newState, finishedInvoiceStatus: [] };
    }
    case AdvancedLoadsConstants.SET_LOAD_SNACKBAR_CONFIG:
      return { ...newState, snackbarConfig: action.payload };
    case AdvancedLoadsConstants.SET_REFRESHED_LOAD:
      const temp = [...newState.displayedLoads];
      const index = temp.findIndex(x => x.id === action.payload.id);

      const newLoad = {
        ...temp[index],
        isChecked: false,
        amount: action.payload.amount,
        customer: action.payload.partnerName,
        pONumber: action.payload.poNumber,
        payment: action.payload.paymentAmount,
        status: action.payload.loadStatus,
      };

      temp[index] = newLoad;

      return { ...newState, refreshedLoad: action.payload, displayedLoads: temp };
    case AdvancedLoadsConstants.SET_LOAD:
      return { ...newState, load: action.payload };
    default:
      return state;
  }
}
