import { BusinessPartnerTypeEnum } from '../enums/BusinessPartnerTypeEnum';
import { PayRateType } from '../../../enums/pay-rate-type';
import { PayRateItemType } from '../../../enums/pay-rate-item-type';
import { BusinessPartner } from '../interfaces/BusinessPartner';
import { BusinessPartnerSummary } from '../interfaces/BusinessPartnerSummary';
import { BusinessPartnerType } from '../interfaces/BusinessPartnerType';
import { BusinessPartnerContact } from '../interfaces/BusinessPartnerContact';
import { BusinessPartnerValidationData } from '../interfaces/BusinessPartnerValidationData';
import { Endorsement } from '../interfaces/Endorsement';
import { cleanPhoneValue, isNullOrWhitespace, phoneReg } from '../../../utils/string-utils';
import { TFunction } from 'i18next';
import moment from 'moment';

export const getPartnerName = (partner: BusinessPartner): string => {
  if (!isNullOrWhitespace(partner?.fullName)) return partner.fullName;
  else if (!isNullOrWhitespace(partner?.companyName)) return partner.companyName;
  else return '';
};

export const getPartnerTypes = (partnerTypes: BusinessPartnerType[], permissions: any[]): BusinessPartnerType[] => {
  if (getIsBasicUser(permissions)) {
    partnerTypes = partnerTypes.filter((type) => type.id === BusinessPartnerTypeEnum.Driver);
  }
  return partnerTypes;
};

export const getPayRateDescritpion = (rateType: PayRateType, t: TFunction) => {
  switch (rateType) {
    case PayRateType.Percentage:
      return t('percentage');
    case PayRateType.TotalMiles:
      return t('total_miles');
    case PayRateType.LoadedAndEmptyMiles:
      return t('loaded_and_empty_miles');
    default:
      return '';
  }
};

export const getPayRateItemDescritpion = (rateType: PayRateItemType, t: TFunction) => {
  switch (rateType) {
    case PayRateItemType.LoadedMiles:
      return t('loaded_rate');
    case PayRateItemType.EmptyMiles:
      return t('empty_rate');
    case PayRateItemType.TotalMiles:
      return t('mile_rate');
    default:
      return t('rate');
  }
};

export const formatPayRateValue = (rateType: PayRateItemType, value: number) => {
  if (!value) return '';
  else if (rateType == PayRateItemType.Percentage) return `${value.toFixed(0)}%`;
  else return `$${value.toFixed(2)}`;
};

export const isDriver = (partnerTypes: BusinessPartnerType[]): boolean => {
  return partnerTypes?.find((t) => t.id === BusinessPartnerTypeEnum.Driver) !== undefined;
};

export const isTruckOwner = (partnerTypes: BusinessPartnerType[]): boolean => {
  return partnerTypes?.find((t) => t.id === BusinessPartnerTypeEnum.TruckOwner) !== undefined;
};

export const isBroker = (partnerTypes: BusinessPartnerType[]): boolean => {
  return partnerTypes?.find((t) => t.id === BusinessPartnerTypeEnum.Broker) !== undefined;
};

export const isShipperReceiver = (partnerTypes: BusinessPartnerType[]): boolean => {
  return partnerTypes?.find((t) => t.id === BusinessPartnerTypeEnum.ShipperOrReceiver) !== undefined;
};

export const isVendor = (partnerTypes: BusinessPartnerType[]): boolean => {
  return partnerTypes?.find((t) => t.id === BusinessPartnerTypeEnum.Vendor) !== undefined;
};

export const isOther = (partnerTypes: BusinessPartnerType[]): boolean => {
  return partnerTypes?.find((t) => t.id === BusinessPartnerTypeEnum.Other) !== undefined;
};

export const hasDriverEditPermission = (permissions: any[]): boolean => {
  return !!permissions.find((p) => p.name === 'tms.partners.drivers.edit' && p.status === 'active');
};

export const hasOwnerEditPermission = (permissions: any[]): boolean => {
  return !!permissions.find((p) => p.name === 'tms.partners.owners.edit' && p.status === 'active');
};

export const hasAdvancedEditPermission = (permissions: any[]): boolean => {
  return !!permissions.find((p) => p.name === 'tms.partners.advancedtypes.read' && p.status === 'active');
};

export const getPartnerEditPermissions = (partnerTypes: BusinessPartnerType[], permissions: any[]): boolean => {
  const isAdvancedTypes =
    isBroker(partnerTypes) || isShipperReceiver(partnerTypes) || isVendor(partnerTypes) || isOther(partnerTypes);

  if (
    (isDriver(partnerTypes) && !hasDriverEditPermission(permissions)) ||
    (isTruckOwner(partnerTypes) && !hasOwnerEditPermission(permissions)) ||
    (isAdvancedTypes && !hasAdvancedEditPermission(permissions))
  ) {
    return false;
  }

  return true;
};

export const getIsBasicUser = (permissions: any[]): boolean => {
  const isBasicuser = !!permissions.find((p: any) => p.name === 'tms.user.basic' && p.status === 'active');
  return isBasicuser;
};

export const showContacts = (partnerTypes: BusinessPartnerType[]): boolean => {
  return isBroker(partnerTypes) || isShipperReceiver(partnerTypes) || isVendor(partnerTypes);
};

export const isContactEmpty = (contact: BusinessPartnerContact): boolean => {
  return (
    isNullOrWhitespace(contact.fullName) &&
    isNullOrWhitespace(contact.phoneNumber) &&
    isNullOrWhitespace(contact.email) &&
    isNullOrWhitespace(contact.department)
  );
};

export function mapPartnerToSummary(partner: BusinessPartner): BusinessPartnerSummary {
  return {
    id: partner.id,
    name: getPartnerName(partner),
    email: partner.email,
    phoneNumber: partner.phoneNumber,
    active: partner.isActive,
    mcNumber: partner.mcNumber,
    types: partner.types,
    profileImageKey: partner.profileImageKey,
    creditRating: partner.creditRating ?? 'NA',
    allowStatusNotifications: partner.allowStatusNotifications,
  };
}

export const cleanValue = (value: string) => {
  return value ? value.replace(/\s/g, '') : value;
};
export const getSecondaryTextForBillingTerms = (t: TFunction) => {
  return [
    { text: t('10_days_due'), id: 1 },
    { text: t('25_days_due'), id: 2 },
    { text: t('30_days_due'), id: 3 },
    { text: t('60_days_due'), id: 4 },
    { text: t('90_days_due'), id: 5 },
  ];
};

export const getEndorsementsDisplayValue = (endorsements: Endorsement[], output: string = ''): string => {
  if (endorsements) {
    let value: string[] = [];
    endorsements.forEach((item) => {
      value.push(`(${item.code})`);
    });
    return value.join('-');
  } else {
    return output;
  }
};

export const isCompanyNameRequired = (partner: BusinessPartner): boolean => {
  return (
    isNullOrWhitespace(partner.companyName) &&
    (isBroker(partner.types) ||
      isVendor(partner.types) ||
      isShipperReceiver(partner.types) ||
      (isOther(partner.types) && isNullOrWhitespace(partner.firstName)))
  );
};

export const cleanPartnerContacts = (contacts: BusinessPartnerContact[]) => {
  const listOfContactsToKeep = new Array<BusinessPartnerContact>();
  contacts.forEach((item: any) => {
    if (!isContactEmpty(item)) {
      listOfContactsToKeep.push(item);
    }
  });
  return listOfContactsToKeep;
};

export const isFirstNameRequired = (partner: BusinessPartner): boolean => {
  return (
    isNullOrWhitespace(partner.firstName) &&
    (isDriver(partner.types) ||
      isTruckOwner(partner.types) ||
      (isOther(partner.types) && isNullOrWhitespace(partner.companyName)))
  );
};

export const isPhoneRequired = (partner: BusinessPartner): boolean => {
  return isNullOrWhitespace(partner.phoneNumber) && (isDriver(partner.types) || isTruckOwner(partner.types));
};

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  return phoneReg.test(cleanPhoneValue(phoneNumber));
};

export const validatePartner = (
  partner: BusinessPartner,
  partnerValidationData: BusinessPartnerValidationData,
  translation: TFunction | null,
  checkRequiredFields: boolean = false,
): BusinessPartnerValidationData => {
  const hasTypesError = partner.types.length === 0;
  partnerValidationData.types.hasError = hasTypesError;
  partnerValidationData.types.errorMessage = hasTypesError ? translate('type_required', translation) : '';

  const hasEmailError = !!partner.email && !isEmail(partner.email);
  partnerValidationData.email.hasError = hasEmailError;
  partnerValidationData.email.errorMessage = hasEmailError ? translate('invalid_email', translation) : '';

  const hasPhoneError = !!partner.phoneNumber && !phoneReg.test(partner.phoneNumber);
  const phoneMissing = checkRequiredFields && isPhoneRequired(partner);
  let phoneErrorMessage = '';
  if (phoneMissing) {
    phoneErrorMessage = translate('phone_number_required', translation);
  } else if (hasPhoneError) {
    phoneErrorMessage = translate('invalid_phone_number', translation);
  }
  partnerValidationData.phoneNumber.hasError = phoneMissing || hasPhoneError;
  partnerValidationData.phoneNumber.errorMessage = phoneErrorMessage;

  const hasEmergencyPhoneError = !!partner.emergencyPhoneNumber && !phoneReg.test(partner.emergencyPhoneNumber);
  partnerValidationData.emergencyPhoneNumber.hasError = hasEmergencyPhoneError;
  partnerValidationData.emergencyPhoneNumber.errorMessage = hasEmergencyPhoneError
    ? translate('invalid_phone_number', translation)
    : '';

  const hasFaxError = !!partner.fax && !phoneReg.test(partner.fax);
  partnerValidationData.faxNumber.hasError = hasFaxError;
  partnerValidationData.faxNumber.errorMessage = hasFaxError ? translate('invalid_fax', translation) : '';

  const postalCodeReg = /^\d{5}(?:[-\s]\d{4})?$/;
  const hasPostalCodeError = !!partner.postalCode && !postalCodeReg.test(partner.postalCode);
  partnerValidationData.postalCode.hasError = hasPostalCodeError;
  partnerValidationData.postalCode.errorMessage = hasPostalCodeError ? translate('invalid_zipcode', translation) : '';

  const firstNameMissing = checkRequiredFields && isFirstNameRequired(partner);
  partnerValidationData.firstName.hasError = firstNameMissing;
  partnerValidationData.firstName.errorMessage = firstNameMissing ? translate('First Name Required', translation) : '';

  const companyNameMissing = checkRequiredFields && isCompanyNameRequired(partner);
  partnerValidationData.companyName.hasError = companyNameMissing;
  partnerValidationData.companyName.errorMessage = companyNameMissing
    ? translate('Company Name Required', translation)
    : '';

  const cdlNumberReg = /^([a-zA-Z0-9]{1,12})$/;
  const hasCdlNumberError = !!partner.cdlNumber && !cdlNumberReg.test(partner.cdlNumber);
  partnerValidationData.cdlNumber.hasError = hasCdlNumberError;
  partnerValidationData.cdlNumber.errorMessage = hasCdlNumberError ? translate('invalid_cdl', translation) : '';

  const hasCdlExpirationError = !!partner.cdlExpiration && !moment(partner.cdlExpiration).isValid();
  partnerValidationData.cdlExpirationDate.hasError = hasCdlExpirationError;
  partnerValidationData.cdlExpirationDate.errorMessage = hasCdlExpirationError
    ? translate('invalid_date', translation)
    : '';

  const hasDateOfBirthError = !!partner.dateOfBirth && !moment(partner.dateOfBirth).isValid();
  partnerValidationData.dateOfBirth.hasError = hasDateOfBirthError;
  partnerValidationData.dateOfBirth.errorMessage = hasDateOfBirthError ? translate('invalid_date', translation) : '';

  if (partner?.contacts?.length > 0) {
    let hasContactsError = false;
    const listOfContacts = cleanPartnerContacts(partner.contacts);
    for (let i = 0; i < listOfContacts.length; i++) {
      const contact = partner.contacts[i];
      const hasEmailError = !!contact.email && !isEmail(contact.email);
      const hasPhoneNumberError = !!contact.phoneNumber && !isValidPhoneNumber(contact.phoneNumber);
      if (hasEmailError || hasPhoneNumberError) {
        hasContactsError = true;
      }
    }
    partnerValidationData.contacts.hasError = hasContactsError;
    partnerValidationData.contacts.errorMessage = hasContactsError ? translate('invalid_contact', translation) : '';
  }

  const websiteReg =
    /^(https?:\/\/)?([\w-]{1,63}\.)+([\w-]{1,63})(:\d+)?(\/\w*)*(\?\w+(=\w+)?)*(#\w+)?(\.\w+)?(&\w+=\w+)*$/;
  const hasWebsiteError = !!partner.webSite && !websiteReg.test(partner.webSite);
  partnerValidationData.website.hasError = hasWebsiteError;
  partnerValidationData.website.errorMessage = hasWebsiteError ? translate('invalid_website', translation) : '';

  partnerValidationData.payRates.hasError = false;
  for (let i = 0; i < partner?.payRates?.length; i++) {
    partnerValidationData.payRates.hasError = partner.payRates[i].rateItems.some((item) => item.rate === null);
    if (partnerValidationData.payRates.hasError) {
      break;
    }
  }

  return partnerValidationData;
};

const translate = (value: string, translation: TFunction | null): string => {
  return translation ? translation(value) : value;
};

export const setPartnerType = (types: BusinessPartnerType[], translation: TFunction) => {
  return types
    .map((t) => partnerNameTranslation(t.id, translation))
    .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
    .join('/');
};

const partnerNameTranslation = (partnerTypeId: number, t: TFunction) => {
  switch (partnerTypeId) {
    case BusinessPartnerTypeEnum.Broker:
      return t('broker');
    case BusinessPartnerTypeEnum.Driver:
      return t('Driver');
    case BusinessPartnerTypeEnum.ShipperOrReceiver:
      return t('shipper_receiver');
    case BusinessPartnerTypeEnum.TruckOwner:
      return t('truck_owner');
    case BusinessPartnerTypeEnum.Vendor:
      return t('vendor');
    default:
      return t('other');
  }
};

const isEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};
