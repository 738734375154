import React, { useState } from 'react';
import CompanyPlan from './company-plan';
import ContactUs from './contact-us';
import ThankYou from './thank-you';
import css from './styles/request-upgrade.scss';
import FirebaseAnalytics from '../../components/core/firebase-analytics';
import { useTranslation } from 'react-i18next';
import { COMPANY_PLANS } from '../../constants/company-plans';
import { useSelector } from 'react-redux';
import { SelfEnrollUpgrade } from './self-enroll-upgrade';
import { FeatureFlags } from '../../enums/featureFlagEnum';
import { useAppSelector } from '../../redux/configureStore';

interface Props {
  hasProTransLite: boolean;
}

export enum ViewState {
  DEFAULT,
  SELF_UPGRADE,
  SUCCESS,
}

const RequestUpgrade: React.FC<Props> = ({ hasProTransLite }: Props) => {
  const ref = React.useRef(null);
  const scrollRef = React.useRef(null);
  const [selectedPlan, setSelectedPlan] = useState({ optionName: '' });
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [viewState, setViewState] = useState(ViewState.DEFAULT);
  const userEmail = useSelector((state: any) => state.app.user?.email);
  const permissions = useSelector((state: any) => state.app.user?.permissions);
  const company = useSelector((state: any) => state.app.company);
  const ptUpgradesIsEnabled = useSelector((state: any) => state.app.features.includes(FeatureFlags.PT_UPGRADES));
  const isPrimaryContact = userEmail?.toLowerCase() === company?.company_email?.toLowerCase();
  const hasFactoring = permissions?.find((perm) => perm.name === 'invoicemanager.User');
  const isBasic = useAppSelector((state: any) => state.app.user.applicationTier) === 'Basic';
  const ptUpgrades = ptUpgradesIsEnabled && isBasic && isPrimaryContact && hasFactoring;
  const { t } = useTranslation();

  const requestUpgradeClick = (planName: string) => {
    if (ptUpgradesIsEnabled && planName === 'Premium' && ptUpgrades) {
      setViewState(ViewState.SELF_UPGRADE);
      return;
    }
    setSelectedPlan({ optionName: planName });
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });

    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.MARKETING_PLANS,
      { label: FirebaseAnalytics.LABELS.REQUEST_UPGRADE, data: planName },
    );
  };

  const renderContent = () => {
    switch (viewState) {
      case ViewState.SELF_UPGRADE:
        return <SelfEnrollUpgrade planName={'Premium'} setViewState={setViewState} />;
      case ViewState.SUCCESS:
        return <div>{t('upgrade_success_message')}</div>;
      default:
        return (
          <>
            <div className={css.title}>
              <div>{t('transportation_management')}</div>
              <div>{t('software_for_fleets_of_any_size')}</div>
            </div>
            <div className={css.subTitle}>
              <div>{t('protransport_is_a_simple_and_user_friendly_transportation_management')}</div>
              <div>{t('feature_designed_to_help_you_manage_your_day_to_day_operations')}</div>
            </div>
            <div className={css.plans}>
              {COMPANY_PLANS.map((plan) => (
                <CompanyPlan
                  plan={plan}
                  key={plan.name}
                  hasProTransLite={hasProTransLite}
                  requestUpgradeClick={() => requestUpgradeClick(plan.name)}
                  ptUpgrades={ptUpgrades}
                />
              ))}
            </div>
            <div ref={scrollRef}>
              {requestSubmitted ? (
                <ThankYou />
              ) : (
                <ContactUs
                  plans={COMPANY_PLANS.map((p) => p.name)}
                  selectedPlan={selectedPlan}
                  setRequestSubmitted={setRequestSubmitted}
                />
              )}
            </div>
          </>
        );
    }
  };

  return (
    <div className={css.mainContainer} ref={ref}>
      {renderContent()}
    </div>
  );
};

export default RequestUpgrade;
