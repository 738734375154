import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import dashboard from '../components/dashboard/store/reducers/appSlice';
import partners from '../components/partners/redux/partnerSlice';
import equipments from '../components/equipments/redux/equipmentSlice';
import { reportingApiSlice } from '../components/dashboard/store/reducers/reportingApiSlice';
import loads from './reducers/loadReducer';
import statements from './reducers/statementReducer';
import app from './reducers/appReducer';
import drivers from './reducers/driverReducer';
import trucks from './reducers/truckReducer';
import fuel from './reducers/fuelReducer';
import ifta from './reducers/iftaReducer';
import businessPartners from './reducers/businessPartnerReducer';
import { agingReport } from './reducers/agingReducer';
import equipment from './reducers/equipmentReducer';
import dispatch from './reducers/dispatchReducer';
import advancedLoads from './reducers/advancedLoadsReducer';
import notifications from './reducers/notificationsReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
  reducer: {
    loads,
    statements,
    app,
    dashboard,
    partners,
    equipments,
    drivers,
    trucks,
    fuel,
    ifta,
    businessPartners,
    agingReport,
    equipment,
    dispatch,
    advancedLoads,
    notifications,
    [reportingApiSlice.reducerPath]: reportingApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(reportingApiSlice.middleware),
});

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
