// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".empty-state__emptyStateContainer___1gX4T{display:flex;width:100%;height:100%;align-items:center;justify-content:center;font-weight:600;font-size:28px;color:#ccc;padding-bottom:50px;flex-direction:column}.empty-state__emptyStateContainer___1gX4T>label{font-size:24px;font-weight:400;color:#666;margin-top:20px}.empty-state__emptyStateContainer___1gX4T>p{font-size:16px;font-weight:400;color:#666;max-width:445px;text-align:center}.empty-state__emptyStateContainer___1gX4T>p>button{color:#0091ea;text-decoration:none;border:0;background-color:rgba(0,0,0,0);font-size:16px;padding:0;cursor:pointer}", "",{"version":3,"sources":["/codebuild/output/src351705177/src/src/components/core/styles/empty-state.scss"],"names":[],"mappings":"AAAA,0CACI,YAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,cAAA,CACA,UAAA,CACA,mBAAA,CACA,qBAAA,CAEA,gDACE,cAAA,CACA,eAAA,CACA,UAAA,CACA,eAAA,CAGF,4CACE,cAAA,CACA,eAAA,CACA,UAAA,CACA,eAAA,CACA,iBAAA,CAEA,mDACE,aAAA,CACA,oBAAA,CACA,QAAA,CACA,8BAAA,CACA,cAAA,CACA,SAAA,CACA,cAAA","file":"empty-state.scss","sourcesContent":[".emptyStateContainer {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n    font-weight: 600;\n    font-size: 28px;\n    color: #cccccc;\n    padding-bottom: 50px;\n    flex-direction: column;\n\n    >label {\n      font-size: 24px;\n      font-weight: 400;\n      color: #666666;\n      margin-top: 20px;\n    }\n\n    >p {\n      font-size: 16px;\n      font-weight: 400;\n      color: #666666;\n      max-width: 445px;\n      text-align: center;\n\n      >button {\n        color: #0091EA;\n        text-decoration: none;\n        border: 0;\n        background-color: transparent;\n        font-size: 16px;\n        padding: 0;\n        cursor: pointer;\n      }\n    }\n  }"]}]);
// Exports
exports.locals = {
	"emptyStateContainer": "empty-state__emptyStateContainer___1gX4T"
};
module.exports = exports;
