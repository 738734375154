import { ComplianceFilters, ComplianceRow } from 'redux/reducers/notificationsReducer';
import * as notificationConstants from '../../constants/notificationsConstants';
import { Notification } from '../../interfaces/notification';

export function setComplianceLoading(loading: boolean) {
  return { type: notificationConstants.SET_COMPLIANCE_LOADING, payload: loading };
}

function mapComplianceNotificationToComplianceRow(notification: Notification): ComplianceRow {
  const jsonString = notification.message.content[0].value;
  const json = JSON.parse(jsonString);
  return {
    id: notification.notificationId,
    type: notification.message.content[0].type,
    expiresIn: getNumberOfDaysUntilExpiration(json.expiration_date),
    profile: json.profile_name,
    description: json.entity_property,
    status: notification.status,
  };
}

function getNumberOfDaysUntilExpiration(expiresIn: string): number {
  const currentDate = new Date();
  const expirationDate = new Date(expiresIn);

  // Calculate the UTC time difference in milliseconds
  const currentUTC = Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());
  const expirationUTC = Date.UTC(
    expirationDate.getUTCFullYear(),
    expirationDate.getUTCMonth(),
    expirationDate.getUTCDate(),
  );

  // Calculate the difference in days
  const timeDifference = expirationUTC - currentUTC;
  return Math.ceil(timeDifference / (1000 * 3600 * 24));
}

export function setComplianceRows(rows: Notification[]) {
  const mappedRows: ComplianceRow[] = rows.map(mapComplianceNotificationToComplianceRow);
  return { type: notificationConstants.SET_COMPLIANCE_ROWS, payload: mappedRows };
}

export function setComplianceFilters(filters: ComplianceFilters) {
  return { type: notificationConstants.SET_COMPLIANCE_FILTERS, payload: filters };
}

export function setNotificationStatus(ids: number[], status: string) {
  return { type: notificationConstants.SET_NOTIFICATION_STATUS, payload: { ids, status } };
}

export function setWexNotifications(wex: Notification[]) {
  return { type: notificationConstants.SET_WEX_NOTIFICATIONS, payload: wex };
}
