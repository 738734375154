import React, { ErrorInfo, ReactNode } from 'react';

export default class ErrorBoundary extends React.Component<{ children: ReactNode }> {
  state: { error: Error; errorInfo: ErrorInfo };
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div
          style={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '10%',
          }}
        >
          <h1 style={{ color: '#666666' }}>Something went wrong.</h1>
          <div style={{ width: 500 }}>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
